#ContactForm {
  align-items: center; /* Keeps submit button centered vertically */
  justify-content: center; /* Centers all form elements horizontally */
  text-align: center;
  border: 4px solid black;
  padding: 20px;
  
  margin: 75px;
}

label {
  font-size: 40px;
  padding: 30px;
  margin: 30px;
  color: orange;
}
.form-input {
  margin-top: 20px;
  text-align: center;
}
.form-input {
  margin-bottom: 20px;
}

input {
  padding: 10px;
}
