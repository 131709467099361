nav {
    height: 40px;
    background-color: gray;
    color: maroon;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

/* Target the Link component */
nav a {
    color: black;
    text-decoration: none;
    padding: 0 10px; /* Add some padding to the links */
    margin: 0 5px; /* Add some margin between the links */
}

/* Apply hover effect */
nav a:hover {
    background-color: lightcoral;
}