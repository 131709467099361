.App {
  text-align: center;
}

h1 {
  font-size: 26px;
  color: whitesmoke;
  text-align: center;
}

p {
  font-size: 18px;
  color: whitesmoke;
  text-align: center;
}
