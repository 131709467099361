.CarouselDiv { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}

.carousel-img {
  width: 650px; /* adjust as needed */
  height: 450px; /* adjust as needed */
  padding: 5px;
  border: 4px solid black;
  object-fit:contain; /* this will ensure that images are scaled correctly */
}

