.Content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Content img{
  padding: 5px;
  margin-top: 10px;
  border: 4px solid black;
}

.Content p {
  font-size: 18px;
}
