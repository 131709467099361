.BlogPostContent {
  display: flex;
  align-items: center;
  border: 4px solid black;
  margin-top: 20px;
  margin-left: 100px;
  margin-right: 50px;
  width: fit-content;
}

.BlogPostContent p{
  padding: 20px;
}

#Post-Date{
  border-left: 4px solid black;
  text-align: center;
}

.BlogPostContent img {
  padding: 5px;
  border-right: 4px solid black;
  height: 130px;
  width: 110px;
}